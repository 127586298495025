export const isPositiveOrZero = (val: any) =>
  val && parseFloat(val) && parseFloat(val) > 0 ? '' : 'Значение должно быть больше, либо равно 0';

export const isPositiveOrEmpty = (val: any) =>
  !val || (parseFloat(val) && parseFloat(val) > 0)
    ? ''
    : 'Значение должно быть больше, либо равно 0';

export const isFioValid = (val: string): string =>
  val && /[~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?\s\d]/g.test(val)
    ? 'Значение не должно содержать спецсимоволы, цифры и пробелы'
    : '';
