import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import Axios, { AxiosResponse } from 'axios';
import qs from 'query-string';
import HttpStatusCode from 'http-status-typed';
import { parseResponseErrorMessage } from '~/utils/parse';
import { jwtExpired, networkError } from '~/config/utils/catcher';

export const fetchCustomerItems: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const orderBy = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  // fetching orders
  const response = await Axios.get(url, {
    params: {
      offset,
      limit: count,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'none' });
    },
    headers: { authorization: token },
  }).catch(jwtExpired);

  if (response?.status !== HttpStatusCode.OK || !response?.data)
    return {
      data: { list: [] },
      error: parseResponseErrorMessage(response) || `Не удалось получить заказы`,
    };

  const list = response.data.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {}, // we use it for salesUserList, teamLeadUserList and responsibleUserList
    error: '',
  };
};

const getCustomerItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  Axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  }).catch(jwtExpired);

export const getCustomerItem: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getCustomerItemData({ url, id, token });

  if (result?.status !== HttpStatusCode.OK || !result?.data)
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: parseResponseErrorMessage(result) || `Не удалось загрузить заказ`,
    };

  return {
    data: result.data.data,
    error: '',
  };
};

export const updateCustomerItem = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  try {
    const result = await Axios.put(
      `${url}/${data.id}`,
      {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        password: data.password,
      },
      {
        headers: { authorization: token },
      }
    )
      .catch(jwtExpired)
      .catch(networkError);

    if (result?.status !== HttpStatusCode.OK || !result?.data) {
      const error = parseResponseErrorMessage(result) || `Can't create item`;

      return { data: {}, error };
    }

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: {},
      error: e.message,
    };
  }
};

export const createCustomerItem = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  try {
    const result = await Axios.post(
      url,
      {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        password: data.password,
        passwordRepeat: data.passwordRepeat,
      },
      {
        headers: { authorization: token },
      }
    )
      .catch(jwtExpired)
      .catch(networkError);

    if (
      (result as AxiosResponse)?.status !== HttpStatusCode.OK ||
      !(result as AxiosResponse)?.data
    ) {
      const error = parseResponseErrorMessage(result) || `Can't create item`;

      return { data: {}, error };
    }

    return {
      data: result?.data,
    };
  } catch (e) {
    return {
      data: {},
      error: e.message,
    };
  }
};

export const blockCustomerItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await Axios.put(
    `${url}/block`,
    {},
    {
      headers: { authorization: token },
    }
  ).catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Can't delete item`;

    return { data: {}, error };
  }

  return {
    data: result.data,
  };
};

export const unBlockCustomerItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await Axios.put(
    `${url}/unblock`,
    {},
    {
      headers: { authorization: token },
    }
  ).catch(jwtExpired);

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Can't delete item`;

    return { data: {}, error };
  }

  return {
    data: result.data,
  };
};
