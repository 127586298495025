import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from '~/config/pages/exchange/view/ExchangeItemViewer/styles.module.scss';
import { IExchangeHistory, IExchangeItem } from '~/config/pages/exchange/types';
import { TablePagination } from '@material-ui/core';
import { Placeholder } from '~/config/components/Placeholder';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import { observer } from 'mobx-react';
import { flow } from 'mobx';
import {
  getPersistedReportsPerPage,
  setPersistedReportsPerPage,
} from '~/config/pages/exchange/utils';
import { getExchangeHistory } from '~/config/pages/exchange/api';
import { CancellablePromise } from 'mobx/lib/api/flow';
import { ExchangeHistoryRow } from '~/config/pages/exchange/view/ExchangeHistoryRow';

interface IProps {
  entity: ExchangeEntity;
  data: IExchangeItem;
  id: string;
}

const TdPlaceholder = () => (
  <td>
    <Placeholder />
  </td>
);

const ExchangeItemViewerHistory: FC<IProps> = observer(({ data: parentData, entity, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IExchangeHistory[]>();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(getPersistedReportsPerPage());
  const [total, setTotal] = useState(0);

  const onChangePage = useCallback((val: number) => setPage(val), [setPage]);
  const onChangeRows = useCallback((val: number) => setRows(val), [setRows]);

  useEffect(() => {
    onChangePage(0);
    setPersistedReportsPerPage(rows);
  }, [onChangePage, rows]);

  useEffect(() => {
    const callee: CancellablePromise<any> = flow(function* () {
      const withToken = entity.parent?.auth?.withToken;

      if (!withToken) return;

      setIsLoading(true);
      const result = yield withToken(getExchangeHistory, {
        url: entity.api?.get.url || '',
        id,
        customerId: parentData.customerId,
        limit: rows,
        offset: page * rows,
      });
      setIsLoading(false);

      if (!result?.data?.data) return;

      setData(result.data.data);
      setTotal(parseInt(result.headers['x-total-count'] || 0, 10));
    })();

    return () => callee.cancel();
  }, [page, rows, id, entity.parent, entity.api, parentData.customerId]);

  const changePage = useCallback((_, page) => onChangePage(page), [onChangePage]);
  const changeRowsPerPage = useCallback((event) => onChangeRows(event.target.value), [
    onChangeRows,
  ]);

  return (
    <table className={styles.table}>
      <colgroup>
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="55%" />
        <col width="15%" />
      </colgroup>

      <thead>
      <tr>
        <th>ID</th>
        <th>Exchange id</th>
        <th>Type</th>
        <th>Message</th>
        <th>Created at</th>
      </tr>
      </thead>

      <tbody>
      {!isLoading &&
      !!data &&
      data.map((item) => <ExchangeHistoryRow item={item} key={item.id} />)}

      {isLoading &&
      [...new Array(rows)].map((_, i) => (
        <tr key={i}>
          {[...new Array(5)].map((_, j) => (
            <TdPlaceholder key={j} />
          ))}
        </tr>
      ))}

      <tr>
        <TablePagination
          page={page}
          rowsPerPage={rows}
          count={total || 0}
          onChangePage={changePage}
          onChangeRowsPerPage={changeRowsPerPage}
        />
      </tr>
      </tbody>
    </table>
  );
});

export { ExchangeItemViewerHistory };
