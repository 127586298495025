import React, { FC, Fragment, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { CustomerEntity } from "~/config/pages/user/components/CustomerEntity";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

interface IProps extends IEntityFieldProps {
  entity: CustomerEntity;
}

const CustomerBlockButton: FC<IProps> = ({ data, entity }) => {

  const onBeforeItemBlockUnblock = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

        if (data?.status !== 10) {
            entity.unBlockItem(data?.id || 0)
        } else {
            entity.blockItem(data?.id || 0)
        }

    },
    [data, entity]
  );

  return (
    <div className={styles.wrap}>

      <Button style={{color: data?.status !== 10 ? 'red' : 'green'}} variant="contained" onClick={onBeforeItemBlockUnblock}>
        <PowerSettingsNewIcon />
      </Button>
    </div>
  );
};

export { CustomerBlockButton };
