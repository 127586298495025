import { IEntityField } from 'icerockdev-admin-toolkit';
import { ExchangeItemViewer } from '~/config/pages/exchange/view/ExchangeItemViewer';
import { ExchangeCustomerField } from '~/config/pages/exchange/components/ExchangeCustomerField';
import { ExchangeSymbolField } from '~/config/pages/exchange/components/ExchangeSymbolField';
import { isPositiveOrZero } from '~/utils/validators';
import { EXCHANGE_STATUSES } from '~/config/pages/exchange/constants';
import { ExchangeCancelButton } from '~/config/pages/exchange/components/ExchangeCancelButton';
import { ExchangeAmountInput } from '~/config/pages/exchange/components/ExchangeAmountInput';
import { ExchangeListCustomCell } from '~/config/pages/exchange/components/ExchangeListCustomCell';

export const EXCHANGE_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: EXCHANGE_STATUSES,
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'customerName',
    label: 'Customer',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'stockExchangeName',
    label: 'Stock',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'algorithmName',
    label: 'Algorithm',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'priceOnCreate',
    label: 'Price on create',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'algorithmSettings',
    label: 'Settings',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'commissionAsset',
    label: 'Comission',
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'profitAsset',
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'initTotalAmountInQuoteAsset',
    label: 'Init Total Amount In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'currentTotalAmountInQuoteAsset',
    label: 'Current Total Amount In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'totalProfitInQuoteAsset',
    label: 'Total Profit In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'totalPnl',
    label: 'Total PNL',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'totalPnlPercent',
    label: 'Total PNL Percent',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'number',
    name: 'stopLossPercent',
    label: 'Stop Loss Percent',
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [{ value: 'algorithmSettingsResponse.stopLossPercent' }],
      }),
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'boolean',
    name: 'trailingUp',
    hideInList: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Date',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'custom',
    name: 'itemList',
    label: 'Items',
    component: ExchangeItemViewer,
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },

  // Editor
  {
    type: 'referenceSelect',
    name: 'stockExchangeId',
    label: 'Stock exchange',
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'referenceSelect',
    name: 'algorithmId',
    label: 'Algorithm',
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'upperPrice',
    label: 'Upper price',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'lowerPrice',
    label: 'Lower price',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'number',
    name: 'gridQuantity',
    label: 'Grid quantity',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'stopLossPercent',
    label: 'Stop Loss Percent',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'investmentAmount',
    label: 'Investment amount',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    name: 'customerId',
    label: 'Customer',
    component: ExchangeCustomerField,
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'custom',
    name: 'symbol',
    label: 'Symbol',
    hideInView: true,
    hideInEdit: true,
    component: ExchangeSymbolField,
    required: true,
    sortable: true,
  },

  // Cancel button
  {
    type: 'custom',
    name: 'cancel',
    label: 'Cancel',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: ExchangeCancelButton,
  },
];
