import React, { FC } from 'react';
import { IExchangeReportItem } from '~/config/pages/exchange/types';
import { formatDateTime } from '~/utils/date';
import styles from './styles.module.scss';
import { SideColored } from '~/config/pages/exchange/components/SideColored';
import { AmountColored } from '~/config/pages/exchange/components/AmountColored';

interface IProps {
  item: IExchangeReportItem;
}

const ExchangeItemRow: FC<IProps> = ({ item }) => (
  <tr>
    <td>{formatDateTime(item.date)}</td>
    <td>
      <SideColored>{item.side}</SideColored>
    </td>
    <td>{item.amount}</td>
    <td>{item.priceBuy}</td>
    <td>{item.priceSell}</td>
    <td>{item.commission}</td>
    <td>
      <AmountColored>{item.profit}</AmountColored>
    </td>
    <td>
      <AmountColored>{item.totalProfit}</AmountColored>
    </td>
  </tr>
);

export { ExchangeItemRow };
