import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useEntity } from 'icerockdev-admin-toolkit';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import { has } from 'ramda';
import { FormControlLabel, Switch } from '@material-ui/core';

const ExchangeHeadButtons = observer(() => {
  const entity = useEntity<ExchangeEntity>();

  const sendNotifications = useMemo(
    () =>
      has('sendNotifications', entity.editorData) ? entity.editorData.sendNotifications : true,
    [entity.editorData]
  );

  const onChange = useCallback(() => {
    entity.setEditorData({
      ...entity.editorData,
      sendNotifications: !sendNotifications,
    });
  }, [entity, sendNotifications]);

  return (
    <div>
      <FormControlLabel
        value="start"
        control={<Switch checked={sendNotifications} onChange={onChange} />}
        label="Send notifications"
        labelPlacement="start"
      />
    </div>
  );
});

export { ExchangeHeadButtons };
