import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';

import styles from 'icerockdev-admin-toolkit/src/containers/login/SignIn/styles.module.scss';
import { useConfig } from 'icerockdev-admin-toolkit/dist/application/utils/hooks';

const SignIn: FC = () => {
  const config = useConfig();
  const auth = config.auth;

  const onForgotPassword = useMemo(
    () =>
      config.auth?.authPasswRestoreFn
        ? () => {
            config.history.push('/restore');
          }
        : undefined,
    [config.history, config.auth, config.auth?.authPasswRestoreFn]
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmitCapture = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      auth!!.sendAuthRequest(email, password);
    },
    [email, password, auth]
  );

  const onEmailChange = useCallback((event) => setEmail(event.target.value), [setEmail]);

  const onPasswordChange = useCallback((event) => setPassword(event.target.value), [setPassword]);

  return (
    <div className={styles.wrap}>
      <form noValidate onSubmit={onSubmitCapture} className={styles.form}>
        <h3 className={styles.header}>Sign in</h3>

        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Login"
          name="email"
          autoComplete="email"
          defaultValue={email}
          onChange={onEmailChange}
          autoFocus
        />

        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          defaultValue={password}
          onChange={onPasswordChange}
          autoComplete="current-password"
          InputProps={{
            endAdornment: onForgotPassword ? (
              <InputAdornment position="end" onClick={onForgotPassword} className={styles.forgot}>
                Forgot password?
              </InputAdornment>
            ) : null,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!email.length || !password.length}
          className={styles.button}
        >
          Sign in
        </Button>
      </form>
    </div>
  );
};

export { SignIn };
