import { JWTAuthProvider } from 'icerockdev-admin-toolkit';
import { authRequestFn, tokenRefreshFn } from './api';
import { FC } from 'react';
import { AuthRouter } from '~/config/components/AuthRouter';

export class CustomJWTAuthProvider extends JWTAuthProvider {
  router: FC = AuthRouter;
}

export default (host: string) =>
  new CustomJWTAuthProvider({
    authRequestFn: authRequestFn(host),
    tokenRefreshFn: tokenRefreshFn(host),
  });
