import React, { FC, Fragment, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import { ExchangeCancelModal } from '~/config/pages/exchange/components/ExchangeCancelModal';

interface IProps extends IEntityFieldProps {
  entity: ExchangeEntity;
}

const ExchangeCancelButton: FC<IProps> = ({ data, entity }) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const onBeforeItemCancel = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsModalShown(true);
    },
    [setIsModalShown]
  );

  const onCancelAccept = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!data?.id) return;

      setIsModalShown(false);

      entity.cancelItem(data.id);
    },
    [entity, data, setIsModalShown]
  );

  const onCancelReject = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsModalShown(false);
    },
    [setIsModalShown]
  );

  if (!data?.status || data.status !== 10) return <Fragment />;

  return (
    <div className={styles.wrap}>
      {isModalShown && <ExchangeCancelModal onApprove={onCancelAccept} onReject={onCancelReject} />}

      <Button color="secondary" variant="contained" onClick={onBeforeItemCancel}>
        <ClearIcon />
      </Button>
    </div>
  );
};

export { ExchangeCancelButton };
