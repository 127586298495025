import {
    createStockExchangeItem,
    fetchStockExchangeItems,
    getStockExchangeItem,
    fetchStockExchangeList, fetchCustomerList,
} from './api';
import { STOCK_EXCHANGE_FIELDS } from './fields';
import { StockExchangeEntity } from '~/config/pages/stockExchange/components/StockExchangeEntity';

export default (host: string) =>
  new StockExchangeEntity({
    title: 'Stock Exchange',
    viewable: false,
    editable: false,
    creatable: true,
    exportable: false,
    items: 50,
    api: {
      list: { url: `${host}/admin/v1/user/exchange/customer-stock-exchange`, method: 'get' },
      get: { url: `${host}/admin/v1/user/stock-exchange`, method: 'get' },
      create: { url: `${host}/admin/v1/customer/stock-exchange`, method: 'post' },
      delete: { url: `${host}/admin/v1/customer`, method: 'delete' },
    },
    menu: {
      label: 'Stock Exchange',
      url: '/stock-exchange',
      enabled: true,
    },
    references: {
      stockExchangeId: {
        getMany: fetchStockExchangeList(host, '/v1/stock-exchange'),
      },
      customerId: {
        getMany: fetchCustomerList(host, '/admin/v1/customer'),
      },
    },
    fields: STOCK_EXCHANGE_FIELDS,
    fetchItemsFn: fetchStockExchangeItems,
    getItemsFn: getStockExchangeItem,
    createItemsFn: createStockExchangeItem,
  });
