import HttpStatusCode from 'http-status-typed';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import { AxiosError, AxiosResponse } from 'axios';

// Intercepts token expiration and passes UNAUNTHORIZED to refresh it

export const jwtExpired = (e: AxiosError): AxiosResponse | undefined => {
  if (e?.response?.status === HttpStatusCode.UNAUTHORIZED) {
    throw new Error(UNAUTHORIZED);
  }

  if (!e?.response) {
    throw e;
  }

  return e.response;
};

const NETWORK_ERROR = 'Network Error';

export const networkError = (e: AxiosError): AxiosResponse | undefined => {
  if (e.message === NETWORK_ERROR) {
    throw new Error('No internet connection');
  }

  if (!e?.response) {
    throw e;
  }

  return e.response;
};
