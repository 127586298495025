import {
  createExchangeItem,
  fetchExchangeAlgorithmStock,
  fetchExchangeItems,
  getExchangeItem,
} from './api';
import { EXCHANGE_FIELDS } from './fields';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';

export default (host: string) =>
  new ExchangeEntity({
    title: 'Exchange',
    viewable: true,
    editable: false,
    creatable: true,
    exportable: false,
    items: 50,
    api: {
      list: { url: `${host}/admin/v1/user/exchange`, method: 'get' },
      get: { url: `${host}/admin/v1/user/exchange`, method: 'get' },
      create: { url: `${host}/admin/v1/user/exchange`, method: 'post' },
      delete: { url: `${host}/admin/v1/user/exchange`, method: 'delete' },
      customers: { url: `${host}/admin/v1/user/exchange/stock-exchange/customer`, method: 'get' },
      symbols: { url: `${host}/admin/v1/user/exchange/stock-exchange`, method: 'get' },
    },
    menu: {
      label: 'Exchange',
      url: '/exchange',
      enabled: true,
    },
    references: {
      algorithmId: {
        getMany: fetchExchangeAlgorithmStock(host, '/admin/v1/user/exchange/algorithm'),
      },
      stockExchangeId: {
        getMany: fetchExchangeAlgorithmStock(host, '/admin/v1/user/exchange/stock-exchange'),
      },
    },
    fields: EXCHANGE_FIELDS,
    fetchItemsFn: fetchExchangeItems,
    getItemsFn: getExchangeItem,
    createItemsFn: createExchangeItem,
  });
