import { parseISO } from 'date-fns/esm';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

export const formatDateTime = (dateISO: string): string =>
  (dateISO && parseISO(dateISO) && format(parseISO(dateISO), 'dd.MM.yyyy HH:mm')) || '';

export const formatFilterDate = (dateISO: string): string => {
  if (!dateISO) return '';
  const date = parseISO(dateISO);
  return (isValid(date) && date.toISOString()) || '';
};
