import React, { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface IProps {
  children: number;
}

const AmountColored: FC<IProps> = ({ children }) => (
  <div className={classNames({ [styles.positive]: children > 0, [styles.negative]: children < 0 })}>
    {children}
  </div>
);

export { AmountColored };
