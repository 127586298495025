import React, { FC, Fragment, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { CustomerEntity } from "~/config/pages/user/components/CustomerEntity";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface IProps extends IEntityFieldProps {
  entity: CustomerEntity;
}

const GoToStockExchangeButton: FC<IProps> = ({ data, entity }) => {

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      window.location.href = `/stock-exchange#customerId=${data?.id}`

    },
    [data, entity]
  );

  return (
    <div className={styles.wrap}>

      <Button variant="contained" onClick={onClick}>
        <ArrowForwardIcon />
      </Button>
    </div>
  );
};

export { GoToStockExchangeButton };
