import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
} from 'icerockdev-admin-toolkit';
import Axios from 'axios';
import qs from 'qs';
import HttpStatusCode from 'http-status-typed';
import { parseResponseErrorMessage } from '~/utils/parse';
import { has } from 'ramda';
import { jwtExpired, networkError } from '~/config/utils/catcher';

export const fetchExchangeItems: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const orderBy = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  const hash = window.location.hash.slice(1, window.location.hash.length);
  const queryParams = qs.parse(hash);

  // fetching orders
  const response = await Axios.get(url, {
    params: {
      offset,
      limit: count,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
      isActive: queryParams._isActive ?? 'true',
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { authorization: token },
  }).catch(jwtExpired);

  if (response?.status !== HttpStatusCode.OK || !response?.data)
    return {
      data: { list: [] },
      error: parseResponseErrorMessage(response) || `Не удалось получить заказы`,
    };

  const list = response.data.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {}, // we use it for salesUserList, teamLeadUserList and responsibleUserList
    error: '',
  };
};

const getExchangeItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  Axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  }).catch(jwtExpired);

export const getExchangeReports = async ({
  url,
  id,
  token,
  limit,
  offset,
}: IEntityGetFunctionProps & { limit: number; offset: number }) =>
  Axios.get(`${url}/report/${id}`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
    },
  }).catch(jwtExpired);

export const getExchangeActiveOrders = async ({
  url,
  id,
  token,
  limit,
  offset,
}: IEntityGetFunctionProps & { limit: number; offset: number }) =>
  Axios.get(`${url}/${id}/order/active`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
    },
  }).catch(jwtExpired);

export const getExchangeItem: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getExchangeItemData({ url, id, token });

  if (result?.status !== HttpStatusCode.OK || !result?.data)
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: parseResponseErrorMessage(result) || `Не удалось загрузить заказ`,
    };

  return {
    data: result.data.data,
    error: '',
  };
};

export const fetchExchangeAlgorithmStock = (host: string, url: string) => async (
  entity: Entity
): Promise<Record<string, string>> => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const response = await withToken(
    ({ token }) =>
      Axios.get(`${host}${url}`, {
        params: {
          offset: 0,
          limit: 9999,
        },
        headers: { authorization: token },
      }).catch(jwtExpired),
    {}
  );

  if (!response?.data?.data || !response.data.data.length) return {};

  return response.data.data
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce(
      (obj: Record<string, string>, item: { name: string; id: number }) => ({
        ...obj,
        [item.id]: item.name,
      }),
      {}
    );
};

export const fetchExchangeCustomers = async ({
  url,
  exchangeId,
  token,
}: {
  url: string;
  exchangeId: string;
  token: string;
}): Promise<Record<string, string>> => {
  const response = await Axios.get(`${url}/${exchangeId}`, {
    params: {
      offset: 0,
      limit: 9999,
    },
    headers: { authorization: token },
  }).catch(jwtExpired);

  if (!response?.data?.data || !response.data.data.length) return {};

  return response.data.data
    .map((it) => ({ ...it, name: [it.lastName, it.firstName, it.middleName].join(' ') }))
    .sort((first, second) => first.name.localeCompare(second.name))
    .reduce(
      (obj: Record<string, string>, item: { firstName: string; lastName: string; id: number }) => ({
        ...obj,
        [item.id]: [item.firstName, item.lastName].join(' '),
      }),
      {}
    );
};

export const fetchExchangeSymbols = async ({
  url,
  exchangeId,
  token,
}: {
  url: string;
  exchangeId: string;
  token: string;
}): Promise<Record<string, string>> => {
  const response = await Axios.get(`${url}/${exchangeId}`, {
    params: {
      offset: 0,
      limit: 9999,
    },
    headers: { authorization: token },
  }).catch(jwtExpired);

  if (!response?.data?.data || !response.data.data.length) return {};

  const options = response.data.data
    .sort((a, b) => a.symbol.localeCompare(b.symbol))
    .reduce(
      (obj: Record<string, string>, item: { symbol: string }) => ({
        ...obj,
        [item.symbol]: item.symbol,
      }),
      {}
    );

  const precision = response.data.data.reduce(
    (acc, item) => ({ ...acc, [item.symbol]: item.pricePrecision }),
    {}
  );

  return { options, precision };
};

export const createExchangeItem = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  try {
    const sendNotifications = has('sendNotifications', data) ? data.sendNotifications : true;

    const result = await Axios.post(
      url,
      {
        customerId: data.customerId,
        stockExchangeId: data.stockExchangeId,
        algorithmId: data.algorithmId,
        symbol: data.symbol,
        sendNotifications,
        algorithmSettings: {
          upperPrice: data.upperPrice,
          lowerPrice: data.lowerPrice,
          gridQuantity: data.gridQuantity,
          investmentAmount: data.investmentAmount,
          trailingUp: data.trailingUp,
          stopLossPercent: data.stopLossPercent,
        },
      },
      {
        headers: { authorization: token },
      }
    )
      .catch(jwtExpired)
      .catch(networkError);

    if (result?.status !== HttpStatusCode.OK || !result?.data) {
      const error = parseResponseErrorMessage(result) || `Can't create item`;

      return { data: {}, error };
    }

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: {},
      error: e.message,
    };
  }
};

export const deleteExchangeItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  try {
    const result = await Axios.delete(url, {
      headers: { authorization: token },
    })
      .catch(jwtExpired)
      .catch(networkError);

    if (result?.status !== HttpStatusCode.OK || !result?.data) {
      const error = parseResponseErrorMessage(result) || `Can't delete item`;

      return { data: {}, error };
    }

    return {
      data: result.data,
    };
  } catch (e) {
    return {
      data: {},
      error: e.message,
    };
  }
};

export const getExchangeHistory = async ({
  url,
  id,
  token,
  limit,
  offset,
  customerId,
}: IEntityGetFunctionProps & { limit: number; offset: number; customerId: number }) =>
  Axios.get(`${url}/${id}/history`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
      customerId,
    },
  });
