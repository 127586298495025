import { Config, HorizontalLayout } from 'icerockdev-admin-toolkit';
import auth from './auth';
import theme from './theme';
import exchange from './pages/exchange';
import user from './pages/user';
import stockExchange from './pages/stockExchange';

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080/api';

export default new Config({
  auth: auth(BASE_URL),
  pages: [exchange(BASE_URL), user(BASE_URL), stockExchange(BASE_URL)],
  layout: HorizontalLayout,
  theme,
  logo: '',
  host: BASE_URL,
});
