import React, { FC, useMemo } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

type IProps = IEntityFieldProps & {
  rows: {
    value: string;
    postfix?: string;
  }[];
};

const getYesOrNo = (val) => (val === 'true' ? 'Yes' : 'No');

const BOOLEAN_STRINGS = ['true', 'false'];

const ExchangeListCustomCell: FC<IProps> = (props) => {
  const fields = useMemo(
    () =>
      props.rows.map(({ value, postfix }) =>
        value.includes('/')
          ? (value
          .split('/')
          .map((str) => props.data?.[str.trim()])
          .join(' / ') ?? '') + (postfix ?? '')
          : (value.split('.').reduce((o, i) => o?.[i], props.data) ?? '') + (postfix ?? '')
      ),
    [props.data, props.rows]
  );

  return (
    <div>
      {fields.map((field, i) => (
        <div key={i}>{(BOOLEAN_STRINGS.includes(field) ? getYesOrNo(field) : field) ?? ''}</div>
      ))}
    </div>
  );
};

export { ExchangeListCustomCell };
