import {
  createCustomerItem,
  fetchCustomerItems,
  getCustomerItem,
  updateCustomerItem
} from './api';
import { CUSTOMER_FIELDS } from './fields';
import { CustomerEntity } from '~/config/pages/user/components/CustomerEntity';

export default (host: string) =>
  new CustomerEntity({
    title: 'Customer',
    viewable: false,
    editable: true,
    creatable: true,
    exportable: false,
    items: 50,
    api: {
      list: { url: `${host}/admin/v1/customer`, method: 'get' },
      get: { url: `${host}/admin/v1/customer`, method: 'get' },
      create: { url: `${host}/admin/v1/customer`, method: 'post' },
      update: { url: `${host}/admin/v1/customer`, method: 'put' },
      delete: { url: `${host}/admin/v1/customer`, method: 'delete' },
    },
    menu: {
      label: 'Customer',
      url: '/customer',
      enabled: true,
    },
    fields: CUSTOMER_FIELDS,
    fetchItemsFn: fetchCustomerItems,
    getItemsFn: getCustomerItem,
    updateItemsFn: updateCustomerItem,
    createItemsFn: createCustomerItem,
  });
