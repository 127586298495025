import React, { useCallback, useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import qs from 'qs';

const TABS = [
  { title: 'Active bots', value: 'true' },
  { title: 'History', value: 'false' },
];

const getTabFromUrl = () => {
  const q = qs.parse(window.location.hash.slice(1, window.location.hash.length));
  return q._isActive ? TABS.findIndex((tab) => tab.value === q._isActive) : 0;
};

const ExchangeListTabs = ({ onChange }) => {
  const [activeTab, setActiveTab] = useState<number>(getTabFromUrl());

  const handleChange = useCallback((_, val) => {
    setActiveTab(val);
  }, []);

  useEffect(() => {
    onChange(TABS[activeTab].value);
  }, [activeTab, onChange]);

  return (
    <div>
      <Tabs indicatorColor="primary" value={activeTab} onChange={handleChange}>
        {TABS.map(({ title }) => (
          <Tab key={title} label={title} />
        ))}
      </Tabs>
    </div>
  );
};

export { ExchangeListTabs };
