import React, { FC, Fragment, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { StockExchangeEntity } from '~/config/pages/stockExchange/components/StockExchangeEntity';
import { StockExchangeDeleteModal } from '~/config/pages/stockExchange/components/StockExchangeDeleteModal';

interface IProps extends IEntityFieldProps {
  entity: StockExchangeEntity;
}

const StockExchangeDeleteButton: FC<IProps> = ({ data, entity }) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const onBeforeItemCancel = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsModalShown(true);
    },
    [setIsModalShown]
  );

  const onCancelAccept = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!data?.id) return;

      setIsModalShown(false);

      entity.deleteItem(data.stockExchangeId, data.customerId);
    },
    [entity, data, setIsModalShown]
  );

  const onCancelReject = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsModalShown(false);
    },
    [setIsModalShown]
  );

  return (
    <div className={styles.wrap}>
      {isModalShown && (
        <StockExchangeDeleteModal onApprove={onCancelAccept} onReject={onCancelReject} />
      )}

      <Button color="secondary" variant="contained" onClick={onBeforeItemCancel}>
        <ClearIcon />
      </Button>
    </div>
  );
};

export { StockExchangeDeleteButton };
