import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import styles from 'icerockdev-admin-toolkit/src/containers/login/ForgotPassword/styles.module.scss';
import { Link } from 'react-router-dom';
import { useConfig } from 'icerockdev-admin-toolkit/dist/application/utils/hooks';

const ForgotPassword: FC = () => {
  const config = useConfig();
  const [email, setEmail] = useState('');

  const onEmailChange = useCallback(
    (event) => {
      setEmail(event.target.value);
    },
    [setEmail]
  );

  const onSubmitCapture = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (!config?.auth?.sendAuthPasswRestore) return;

      config.auth.sendAuthPasswRestore(email);
    },
    [config, email]
  );

  return (
    <div className={styles.wrap}>
      <form noValidate onSubmit={onSubmitCapture} className={styles.form}>
        <h3 className={styles.header}>Restore password</h3>

        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          defaultValue={email}
          onChange={onEmailChange}
          autoFocus
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!email.length}
          className={styles.button}
        >
          Restore
        </Button>

        <Button
          type="button"
          component={Link}
          to="/"
          variant="text"
          fullWidth
          className={styles.cancel}
        >
          Cancel
        </Button>
      </form>
    </div>
  );
};

export { ForgotPassword };
