import React, { FC, useEffect } from 'react';
import { EntityFieldSelect, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';

const ExchangeCustomerField: FC<IEntityFieldProps> = observer(({ entity, ...props }) => {
  useEffect(() => {
    if (!entity) return;

    const exchangeId = entity?.editorData?.stockExchangeId;

    if (!exchangeId) return;

    const query = (entity as ExchangeEntity).getExchangeCustomers(
      entity.editorData?.stockExchangeId
    );

    return () => query?.cancel();
  }, [entity, entity?.editorData?.stockExchangeId]);

  return <EntityFieldSelect entity={entity} {...props} />;
});

export { ExchangeCustomerField };
