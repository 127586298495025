import React, { FC } from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type IProps = {
  title?: string;
  text?: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
};

const EntityModal: FC<IProps> = ({
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  open,
  onClose,
  onCancel,
  onConfirm,
}) => (
  <Dialog open={open} onClose={onClose}>
    {title && <DialogTitle>{title}</DialogTitle>}
    {text && (
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
    )}
    {(cancelButtonText || confirmButtonText) && (
      <DialogActions>
        {cancelButtonText && (
          <Button type="button" color="default" variant="outlined" onClick={onCancel}>
            {cancelButtonText}
          </Button>
        )}

        {confirmButtonText && (
          <Button type="button" variant="contained" color="primary" onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    )}
  </Dialog>
);

export { EntityModal };
