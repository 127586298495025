import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { EntityFieldNumber, IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import { TextField } from '@material-ui/core';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import { IMaskInput } from 'react-imask';

interface IProps extends IEntityFieldProps {}

const COMPONENT = ({ inputRef, onChange, ...other }) => (
  <IMaskInput onChange={onChange} {...other} mask={Number} inputRef={inputRef} />
);

const INPUT_PROPS = {
  mask: Number, // enable number mask
  signed: false, // disallow negative
  normalizeZeros: true, // appends or removes zeros at ends
  padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
  radix: '.', // fractional delimiter
  mapToRadix: ['.', ','], // symbols to process as radix
  // also Pattern options can be set
  lazy: false,

  // and other common options
  autofix: true,
};
const ExchangeAmountInput: FC<IProps> = observer(({ value, isEditing, isFiltering, ...props }) => {
  const entity = useEntity<ExchangeEntity>();

  const symbol = useMemo(() => entity.editorData?.symbol || '', [entity.editorData]);
  const scale = useMemo(() => (symbol && entity.symbolPrecision?.[symbol]) || 1, [
    entity.symbolPrecision,
    symbol,
  ]);

  if (!isEditing || isFiltering)
    return (
      <EntityFieldNumber {...props} isEditing={isEditing} isFiltering={isFiltering} value={value} />
    );

  const onChange = useCallback(
    (event) => {
      if (!props.handler) return;

      props.handler(event.target.value);
    },
    [props]
  );

  const placeholder = useMemo(() => {
    return `0.${[...new Array((scale || 0) + 1)].map(() => '0').join('')}`;
  }, [scale]);

  return (
    <TextField
      type="text"
      value={(value && value.toString()) || ''}
      variant="outlined"
      placeholder={placeholder}
      disabled={!symbol}
      onChange={onChange}
      label={props.label}
      inputProps={{ scale, ...INPUT_PROPS }}
      helperText={!symbol && 'Select stock and symbol first'}
      InputProps={{
        inputComponent: COMPONENT as any,
      }}
    />
  );
});

export { ExchangeAmountInput };
