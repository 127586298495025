import React, { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface IProps {}

const SideColored: FC<IProps> = ({ children }) => (
  <div
    className={classNames({ [styles.sell]: children === 'sell', [styles.buy]: children === 'buy' })}
  >
    {children}
  </div>
);

export { SideColored };
