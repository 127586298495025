import React, { FC, useState } from 'react';
import { EntityModal } from '~/config/components/EntityModal';
import { EntityViewer, IEntityViewer } from '~/config/components/EntityCreateViewer';
import { ENTITY_ERRORS } from 'icerockdev-admin-toolkit';

const ExchangeCreatorBody: FC<IEntityViewer> = ({
  fields,
  errors,
  url,
  onSave,
  onCancel,
  onResetFieldError,
  isEditing,
  isLoading,
  setEditorData,
  data,
  getItem,
  cancelGetItem,
  viewable,
  withToken,
  entity,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleSave = () => {
    try {
      if (!entity.validateSubmitFields(data, true)) {
        throw new Error(ENTITY_ERRORS.INCORRECT_INPUT);
      }
      handleModalOpen();
    } catch (e) {
      entity.error = e;
      entity.parent?.notifications.showError(e.message);
    }
  };

  const handleConfirm = () => {
    onSave();
    handleModalClose();
  };

  return (
    <>
      <EntityModal
        title="Are you sure you want to create a bot?"
        open={isModalOpen}
        confirmButtonText="Yes"
        onConfirm={handleConfirm}
        cancelButtonText="No"
        onCancel={handleModalClose}
        onClose={handleModalClose}
      />
      <EntityViewer
        fields={fields}
        errors={errors}
        url={url}
        onSave={handleSave}
        onCancel={onCancel}
        onResetFieldError={onResetFieldError}
        isEditing={isEditing}
        isLoading={isLoading}
        setEditorData={setEditorData}
        data={data}
        getItem={getItem}
        cancelGetItem={cancelGetItem}
        viewable={viewable}
        withToken={withToken}
        entity={entity}
      />
    </>
  );
};

export { ExchangeCreatorBody };
