import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  active?: boolean;
  value?: any;
}

const Placeholder: FC<IProps> = ({ value = '', active = true }) =>
  active ? <div className={styles.placeholder}>&nbsp;</div> : value;

export { Placeholder };
