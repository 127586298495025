import { IEntityField } from 'icerockdev-admin-toolkit';
import { CUSTOMER_STATUSES } from '~/config/pages/user/constants';
import { FieldPassword } from '~/config/pages/user/components/FieldPassword';
import { CustomerBlockButton } from '~/config/pages/user/components/CustomerBlockButton';
import { GoToStockExchangeButton } from '~/config/pages/user/components/GoToStockExchangeButton';
import { isFioValid } from '~/utils/validators';

export const CUSTOMER_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: CUSTOMER_STATUSES,
    hideInView: true,
    filterable: true,
    hideInCreate: true,
    sortable: true,
    required: true,
  },
  {
    type: 'string',
    name: 'firstName',
    label: 'First Name',
    hideInView: true,
    validator: isFioValid,
    required: true,
  },
  {
    type: 'string',
    name: 'middleName',
    label: 'Middle Name',
    hideInView: true,
    validator: isFioValid,
    required: true,
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last Name',
    hideInView: true,
    validator: isFioValid,
    required: true,
  },
  {
    type: 'phone',
    name: 'phone',
    filterable: true,
    label: 'Phone',
    hideInView: true,
    required: true,
  },
  {
    type: 'string',
    name: 'email',
    filterable: true,
    label: 'Email',
    hideInView: true,
    required: true,
  },

  // Editor
  {
    type: 'custom',
    component: FieldPassword,
    name: 'password',
    label: 'Password',
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'custom',
    component: FieldPassword,
    name: 'password',
    label: 'Password',
    hideInList: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    component: FieldPassword,
    name: 'passwordRepeat',
    label: 'Password Repeat',
    hideInEdit: true,
    hideInList: true,
    required: true,
  },

  // Block/Unblock button
  {
    type: 'custom',
    name: 'block',
    label: 'Block/Unblock',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: CustomerBlockButton,
  },

  // Go to stock exchange
  {
    type: 'custom',
    name: 'goToStockExchange',
    label: 'Open Stock Exchange',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: GoToStockExchangeButton,
  },
];
