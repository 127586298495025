import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, flow, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { ExchangeItemViewer } from '~/config/pages/exchange/view/ExchangeItemViewer';
import { CancellablePromise } from 'mobx/lib/api/flow';
import {
  deleteExchangeItem,
  fetchExchangeCustomers,
  fetchExchangeSymbols,
} from '~/config/pages/exchange/api';
import { IExchangeItem } from '~/config/pages/exchange/types';
import { ExchangeHeadButtons } from '~/config/pages/exchange/components/ExchangeHeadButtons';
import { Typography } from '@material-ui/core';
import { ExchangeListTabs } from '../ExchangeListTabs';
import { EntityFooter } from '~/config/components/EntityFooter';
import { EntityHead } from '~/config/components/EntityHead';
import { ExchangeCreatorBody } from '~/config/pages/exchange/components/ExchangeCreatorBody';

class ExchangeEntity extends Entity {
  @observable symbolPrecision: Record<string, number> = {};

  @observable getExchangeCustomersInstance?: CancellablePromise<any>;

  @observable isActive: 'true' | 'false' = 'true';

  @action
  setIsActive = (isActive: 'true' | 'false') => {
    this.isActive = isActive;
  };

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(
      () => [this.isActive, this.filters, this.sortBy, this.sortDir, this.page, this.items],
      () => {
        this.setFiltersWindowHash();
      }
    );

    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => [this.isActive, this.page], this.fetchItems);
    this.fetchItems();
  };

  @action
  setFiltersWindowHash = () => {
    const filters = this.getFilters().reduce(
      (obj, filter) => ({ ...obj, [filter.name]: filter.value }),
      {}
    );

    const params = new URLSearchParams({
      ...filters,
      _isActive: this.isActive,
      _page: this.page.toString(),
      _sortBy: this.sortBy.toString(),
      _sortDir: this.sortDir.toString(),
      _items: this.items.toString(),
    });

    window.location.hash = params.toString();
  };

  @action
  setCustomerIdOptions = (options: Record<string, string>) => {
    this.fields = this.fields.map((field) =>
      field.name === 'customerId' ? { ...field, options } : field
    );
  };

  @action
  getExchangeCustomers = (exchangeId): CancellablePromise<any> => {
    if (this.getExchangeCustomersInstance && this.getExchangeCustomersInstance.cancel) {
      this.getExchangeCustomersInstance.cancel();
    }

    this.getExchangeCustomersInstance = flow(function* (this: ExchangeEntity) {
      const result = yield this.parent?.auth?.withToken(fetchExchangeCustomers, {
        url: this?.api?.customers.url,
        exchangeId,
      });

      this.setCustomerIdOptions(result);
    }).bind(this)();

    return this.getExchangeCustomersInstance;
  };

  getExchangeSymbolInstance?: CancellablePromise<any>;

  @action
  setSymbolOptions = (options: Record<string, string>) => {
    this.fields = this.fields.map((field) =>
      field.name === 'symbol' ? { ...field, options } : field
    );
  };

  @action
  setSymbolPrecision = (precision: Record<string, number>) => {
    this.symbolPrecision = precision;
  };

  @action
  getExchangeSymbols = (exchangeId): CancellablePromise<any> => {
    if (this.getExchangeSymbolInstance && this.getExchangeSymbolInstance.cancel) {
      this.getExchangeSymbolInstance.cancel();
    }

    this.getExchangeSymbolInstance = flow(function* (this: ExchangeEntity) {
      const result = yield this.parent?.auth?.withToken(fetchExchangeSymbols, {
        url: this?.api?.symbols.url,
        exchangeId,
      });

      this.setSymbolOptions(result.options);
      this.setSymbolPrecision(result.precision);
    }).bind(this)();

    return this.getExchangeSymbolInstance;
  };

  @action
  cancelItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    await this.parent.auth.withToken(deleteExchangeItem, { url: `${this?.api?.delete.url}/${id}` });
    await this.fetchItems();

    this.isLoading = true;
  };

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => {
      return (
        <ExchangeItemViewer
          id={id}
          data={this.editorData as IExchangeItem}
          isLoading={this.isLoading}
          entity={this}
        />
      );
    });
  }

  @computed
  get CreatorHeadButtons() {
    return ExchangeHeadButtons;
  }

  @computed
  get CreatorBody() {
    return observer(() => (
      <ExchangeCreatorBody
        fields={this.fields}
        errors={this.editorFieldErrors}
        url={this.menu.url}
        onSave={this.createItem}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.createEmptyItem}
        cancelGetItem={this.getItemsCancel}
        viewable={this.viewable}
        withToken={this.parent?.auth?.withToken}
        entity={this}
      />
    ));
  }

  @computed
  get ListHeadTitle() {
    return observer(() => (
      <div style={{ width: '100%' }}>
        <Typography variant="h4" style={{ flex: 1 }}>
          {this.title}
        </Typography>
        <ExchangeListTabs onChange={this.setIsActive} />
      </div>
    ));
  }

  @computed
  get ListHead() {
    return observer(() => (
      <EntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListFooter() {
    return observer(() => (
      <EntityFooter
        page={this.page}
        itemsPerPage={this.itemsPerPage}
        items={this.items}
        totalCount={this.totalCount}
        setPage={this.setPage}
        setPerPage={this.setPerPage}
      />
    ));
  }
}

export { ExchangeEntity };
