import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogTitle } from '@material-ui/core';

interface IProps {
  onApprove: (event: any) => void;
  onReject: (event: any) => void;
}

const StockExchangeDeleteModal: FC<IProps> = ({ onApprove, onReject }) => (
  <Dialog open>
    <DialogTitle id="alert-dialog-title">Are you sure want to delete stock exchange?</DialogTitle>

    <DialogActions>
      <Button onClick={onApprove} color="secondary" autoFocus>
        Delete
      </Button>

      <Button onClick={onReject} color="primary" variant="contained">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export { StockExchangeDeleteModal };
