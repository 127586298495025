import React, { FC } from 'react';
import { IExchangeOrder } from '~/config/pages/exchange/types';
import { formatDateTime } from '~/utils/date';
import { ORDER_STATUSES } from '~/config/pages/exchange/constants';
import { SideColored } from '~/config/pages/exchange/components/SideColored';

interface IProps {
  item: IExchangeOrder;
}

const ExchangeOrderRow: FC<IProps> = ({ item }) => (
  <tr>
    <td>{item.id}</td>
    <td>
      <SideColored>{item.sideLabel}</SideColored>
    </td>
    <td>{item.quantity}</td>
    <td>{item.executedQuantity}</td>
    <td>{item.price}</td>
    <td>{item.typeLabel}</td>
    <td>{ORDER_STATUSES[item.status]}</td>
    <td>{formatDateTime(item.createdAt)}</td>
  </tr>
);

export { ExchangeOrderRow };
