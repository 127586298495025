import axios from 'axios';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import HttpStatusCode from 'http-status-typed';

export const authRequestFn = (host: string) => async (
  login: string,
  password: string
): Promise<{
  user: {
    id?: number | undefined;
    email?: string | undefined;
    username?: string | undefined;
    role?: string | undefined;
    token?: string | undefined;
  };
  tokens: {
    access: string;
    refresh: string;
  };
  error: string;
}> => {
  try {
    const response = await axios
      .post(`${host}/admin/v1/auth/signin`, { email: login, password })
      .catch((e) => {
        if (e?.response?.status === HttpStatusCode.UNAUTHORIZED) {
          throw new Error(e.response.data.message);
        }

        return e?.response;
      });

    if (!response?.data?.data?.accessToken) {
      throw new Error('User not found');
    }

    return {
      user: {
        username: login,
      },
      tokens: {
        access: response.data.data.accessToken,
        refresh: response.data.data.refreshToken,
      },
      error: '',
    };
  } catch (e) {
    return { user: {}, tokens: { access: '', refresh: '' }, error: e.message };
  }
};

export const tokenRefreshFn = (host) => async (refreshToken) => {
  try {
    const result = await axios
      .post(`${host}/admin/v1/auth/refresh`, { refreshToken })
      .catch((e) => e.response);
    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }
    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};
