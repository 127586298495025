export const EXCHANGE_STATUSES = {
  10: 'Active',
  20: 'Closed',
  30: 'Cancelled',
  35: 'Cancelled with error',
};

export const ORDER_STATUSES = {
  10: 'New',
  20: 'Partially Filled',
  30: 'Filled',
  40: 'Cancelled',
  50: 'Rejected',
  60: 'Expired',
  70: 'Liquidation',
};
