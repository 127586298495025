import React, { FC } from 'react';
import { IExchangeHistory } from '~/config/pages/exchange/types';
import { formatDateTime } from '~/utils/date';

interface IProps {
  item: IExchangeHistory;
}

const ExchangeHistoryRow: FC<IProps> = ({ item }) => (
  <tr>
    <td>{item.id}</td>
    <td>{item.exchangeId}</td>
    <td>{item.type}</td>
    <td>{item.message}</td>
    <td>{formatDateTime(item.createdAt)}</td>
  </tr>
);

export { ExchangeHistoryRow };
